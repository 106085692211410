.Detail-detail-container {
  max-width: var(--inset-width);
  display: flex;
  align-items: center;
  margin: auto;
  font-size: 1em;
  background-color: rgba(var(--lilac-dark), 0.1);
}

.Detail-detail-container.Detail-margin-top {
  margin-top: 1em;
}


.Detail-detail-container:last-of-type {
  margin-bottom: 1em;
}

.Detail-detail-container>div:first-of-type {
  text-align: end;
  flex: 1;
  padding: 0 1em;
  border-inline-end: 1px solid rgba(var(--sage), 0.24);
  font-weight: bold;
}

.Detail-detail-container>div:last-of-type {
  padding: 0 1em;
  text-align: start;
  flex: 2;
}
