:root {
  --background-color: #282c34;
  --text-color: rgba(255, 255, 255, 0.86);
  --text-color-rgb: 255, 255, 255;
  --sage: 141, 161, 136;
  --sage-dark: 91, 106, 87;
  --lilac: 176, 163, 172;
  --lilac-dark: 129, 96, 119;
  --easing: cubic-bezier(0.075, 0.82, 0.165, 1);

  --max-width: 1280px;
  --margin: 16px;
  --inset-width: calc(var(--max-width) - (var(--margin) * 6));
  --bp-phone: min-width: 480px;
  --bp-desktop: min-width: 1150px;
  --bp-tablet: min-width: 769px;
  --bp-full-bleed: min-width: 1280px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #2d2d2d;
    --text-color: rgba(255, 255, 255, 0.86);
  }

  html {
    color-scheme: dark;
  }
}

h2 {
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 0;
}

a,
a:visited,
a:active {
  color: rgba(var(--lilac-dark), 1);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.full-bleed {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  max-width: var(--max-width);
  width: 100%;
}

.full-bleed.center {
  width: calc(100% - 16px);
  margin: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  text-align: center;
}

hr {
  margin-bottom: 1em;
  width: 128px;
  border-color: rgba(var(--lilac), .24);
}

a {
  font-weight: bold;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: var(--margin);
  max-width: calc(var(--max-width) - var(--margin) * 2);
  padding: 0 var(--margin);
  width: calc(100% - var(--margin) * 2);
  margin: auto;
}

.span-12 {
  grid-column: span 12;
}

.span-8 {
  grid-column: span 8;
}


.span-6 {
  grid-column: span 6;
}

.span-4 {
  grid-column: span 4;
}

.span-2 {
  grid-column: span 2;
}

img.not-good-with-computer {
  margin: 8px;
  width: calc(100% - 16px);
  border-radius: 8px;
}

.react-photo-gallery--gallery img {
  border-radius: 8px;
}

@media (max-width: 480px) {
  p.center.rsvp-reminder {
    text-align: start;
  }
}
