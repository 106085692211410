.TextInput-container {
  max-width: var(--inset-width);
  display: flex;
  flex-direction: column;
  margin: auto;
  font-size: 1em;
  margin-bottom: 1em;
}

.TextInput-container>div:first-of-type {
  flex: 1;
  padding: 0;
  font-weight: bold;
}

.TextInput-container>div:last-of-type {
  padding: 0;
  flex: 1;
  flex-shrink: 1;
}

.TextInput-container label {
  height: 36px;
  width: calc(100% - 1em - 2px);
  display: inline-flex;
  align-items: center;
  color: rgba(var(--text-color-rgb), 0.82);
  padding: 0 0.5em;
  font-size: 1em;
  background-color: rgba(var(--lilac-dark), .36);
  border: 1px solid rgba(var(--lilac-dark), .36);
  border-radius: 4px 4px 0 0;
}

.TextInput-container input {
  width: calc(100% - 1em - 2px);
  height: 36px;
  padding: 0 0.5em;
  color: rgba(var(--text-color-rgb), 0.82);
  font-size: 1em;
  background-color: rgba(var(--lilac-dark), .1);
  border: 1px solid rgba(var(--sage), 0.24);
  border-radius: 0 0 4px 4px;
}

.TextInput-container input::placeholder {
  color: rgba(var(--text-color-rgb), 0.36);
}

@media (min-width: 769px) {
  .TextInput-container {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }

  .TextInput-container>div:first-of-type {
    display: flex;
    flex: 0;
  }

  .TextInput-container>div:last-of-type {
    flex: 1 0 66%;
    display: flex;
    justify-content: flex-start;
  }

  .TextInput-container label {
    display: flex;
    justify-content: end;
    width: unset;
    min-width: 216px;
    border-radius: 4px 0 0 4px;
    border-right: none;
  }

  .TextInput-container input {
    width: unset;
    border-radius: 0 4px 4px 0;
    min-width: calc(66% - 1em - 2px);
  }
}
