@media (prefers-reduced-motion: no-preference) {

  .NavMenu nav>ul>li,
  .NavMenu nav>ul>li:hover {
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  }
}

.NavMenu {
  position: sticky;
  top: 0;
  z-index: 5;
  background: rgba(45, 45, 45, 0.56);
  backdrop-filter: blur(4px);
}

.NavMenu nav {
  min-height: 2em;
  margin: auto;
}

.NavMenu nav>hr {
  margin-top: -1em;
}

.NavMenu nav>ul {
  margin: 8px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NavMenu nav>ul>span {
  display: none;
}

.NavMenu nav>ul>li {
  display: inline-block;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  font-size: 0.9em;
}

.NavMenu nav>ul>li:not(:last-of-type)::after {
  content: "";
  height: 16px;
  position: absolute;
  top: calc(50% - 8px);
  right: 0;
  border-right: 2px solid rgba(var(--lilac), .24);
}

.NavMenu nav>ul>li:hover:not(.NavMenu-title) {
  background-color: rgba(255, 255, 255, 0.1);
}

.NavMenu nav>ul>li>a {
  display: block;
  color: rgba(var(--lilac), 1);
  text-decoration: none;
  padding: 1em;
  font-weight: bold;
}

li.NavMenu-title {
  margin: auto 2em;
}

.NavMenu-title h1 {
  transition: var(--easing) .5s;
}

.NavMenu-title.mobile:not(.small) h1 {
  margin-top: 0px;
  margin-bottom: 16px;
  font-size: 2em;
}

.NavMenu-title.small.mobile h1 {
  transform: scale(0);
  height: 0;
  margin: 0;
  opacity: 0;
}

.NavMenu-title.desktop {
  display: none;
}

@media (min-width: 480px) {
  .NavMenu nav>ul>li {
    font-size: 1em;
  }
}

@media (min-width: 769px) {

  .NavMenu nav>ul>li.NavMenu-title::after,
  .NavMenu nav>ul>li:nth-of-type(2)::after {
    border-right: none;
  }

  .NavMenu-title.mobile {
    display: none;
  }

  .NavMenu-title.desktop {
    display: block;
  }

  .NavMenu-title.small h1 {
    font-size: 1.3em;
    opacity: 1;
  }

  .NavMenu nav>ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  .NavMenu nav>ul>span {
    flex: 1;
    border-bottom: 2px solid rgba(var(--lilac), .24);
    display: unset;
  }

  .NavMenu-title h1 {
    font-size: 1.3em;
  }

  .NavMenu nav>ul>li {
    min-width: 128px;
  }
}

@media (min-width: 1150px) {
  .NavMenu-title h1 {
    font-size: 3.5em;
  }

  .NavMenu-title.small h1 {
    font-size: 2em;
    opacity: 1;
  }
}
