.App {
  display: grid;
  grid-template-rows: auto 1fr auto;
  text-align: start;
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
}

.App-header {
  background-color: var(--background-color);
  min-height: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: var(--text-color);
}

button.App-button {
  cursor: pointer;
}

a.App-button,
a.App-button:visited,
button.App-button {
  display: inline-block;
  padding: 0.5em 1em;
  background: rgba(var(--sage-dark), 0.96);
  border-radius: 24px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.72);
  border: none;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  transition: var(--easing) .5s;
}

a.App-button:hover,
button.App-button:hover {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
  background: rgba(var(--sage), 0.72);
  transition: var(--easing) .5s;
  color: rgba(255, 255, 255, 0.76);
}

main {
  flex: 1;
}

footer {
  font-size: .8em;
  margin-bottom: 1em;
  text-align: center;
}
