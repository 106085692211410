.Home-wedding-details>p {
  padding: 0;
  margin: 0;
}

.splash {
  width: 100%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  /* filter: grayscale(100%); */
  /* border-radius: 32px; */
}

.Home-splash {
  margin: 1em;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  overflow: hidden;
  /* box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f; */
}

.Home-splash>div {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.24);
  padding: 0;
  backdrop-filter: blur(8px);
  align-items: flex-end;
}

.Home-splash>div>div {
  margin-right: 1em;
  display: flex;
  /* flex-direction: column; */
  padding: 12px;
  align-items: center;
  justify-content: center;
}

.Home-splash p {
  font-size: 1.4em;
  margin: 0.2em 1em;
  font-weight: bold;
}

#lol-butts {
  position: absolute;
  background: transparent;
  top: 128px;
  left: 53px;
  height: 5px;
  width: 5px;
  cursor: not-allowed;
}

@media (min-width: 769px) {
  .splash {
    aspect-ratio: 16 / 9;
  }
}

@media (min-width: 1280px) {
  .Home-splash {
    border-radius: 8px;
  }
}
