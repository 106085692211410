.Rsvp-submit-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.Rsvp-error {
  color: #fb7070;
}

@media (min-width: 480px) {
  .Rsvp-error {
    text-align: end;
  }
}

@media (min-width: 769px) {
  .Rsvp-thanks {
    text-align: center;
  }
}
